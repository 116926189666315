<div class="body">
    <div class="login">
        <mat-spinner *ngIf="loading" class="spinner" diameter="50"></mat-spinner>
        <form *ngIf="!loading" [formGroup]="form" (ngSubmit)="login()">
            <img src="./assets/img/user_login.png" width="50%">
            <h1>Login</h1>
            <mat-form-field class="width" appearance="fill">
                <mat-label>Usuario</mat-label>
                <input matInput autocomplete="off" formControlName="email">
            </mat-form-field>
            <mat-form-field class="width" appearance="fill">
                <mat-label>Contraseña</mat-label>
                <input type="password" matInput autocomplete="off" formControlName="password">
            </mat-form-field>
            <br>
            <button type="submit" class="width button" mat-raised-button color="primary" [disabled]="form.invalid">Ingresar</button>
        </form>
    </div>
</div>