import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { LoginService } from './services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [LoginService]
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  loading = false;

  constructor(private fb: FormBuilder, private _snackBar: MatSnackBar, private router: Router, 
    private auth: AngularFireAuth, private service: LoginService) { 
    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    })
  }

  ngOnInit(): void {
  }

  login() {
    const { email, password } = this.form.value;
    this.auth.signInWithEmailAndPassword(email, password)
      .then((data) => this.loadingDashboard(data))
      .catch(() => {
        this.error();
        this.form.reset();
      });
  }

  error() {
    this._snackBar.open('Usuario o contraseña ingresado son inválidos', '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom'
    })
  }

  loadingDashboard(data: any) {
    this.loading = true;
    const uid = data.user.uid;
    localStorage.setItem('uid', uid);
    data.user.getIdToken().then((idToken: any) => {
      localStorage.setItem('userToken', idToken);
      return idToken;
    }).then((idToken: any) => {
      this.service.getRolesByUid(idToken, uid).subscribe(
        result => {
          if (result != null) {
            localStorage.setItem('roles', JSON.stringify(result.data));
            this.service.getUser(uid).subscribe(
              data => {
                if (data !== null){
                  localStorage.setItem('user', JSON.stringify(data.data));
                  this.router.navigate(['/dashboard']);
                }
              },
              error => '',
              () => ''
            );
          }
        },
        error => '',
        () => ''
      );
    })
  }

}
