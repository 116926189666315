import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }

  getUser(uid: any): Observable<any> {
    const headers = new HttpHeaders()
    headers.append('Content-type', 'application/x-www-form-urlencoded');

    return this.http.get(environment.path + '/user/' + uid, { headers: headers })
  }

  getRolesByUid(tokenId: any, uid: any): Observable<any> {
    const headers = new HttpHeaders()
    headers.append('Content-type', 'application/x-www-form-urlencoded');

    return this.http.get(environment.path + '/user/' + uid + '/roles' + '?tokenId=' + tokenId, { headers: headers })
  }
}
