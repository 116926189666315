// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCLpl0AdD-agQL7Z5cMdad8io2ht3dVvtA",
    authDomain: "prod-marketing-digital.firebaseapp.com",
    projectId: "prod-marketing-digital",
    storageBucket: "prod-marketing-digital.appspot.com",
    messagingSenderId: "87419222328",
    appId: "1:87419222328:web:f4dec929eb99bdc150a9cd",
    measurementId: "G-PFG5DRMTT8"
  },
  path: 'https://digital-marketing-api-gateway-145r5bnc.uc.gateway.dev',
  shortcode_path: 'https://admin.api.slco.in',
  shortcode_logger_path: 'https://logging.api.slco.in',
  coupon_path: 'https://admin.api.digitalcupon.com',
  coupon_logger: 'https://logging.api.digitalcupon.com',
  campaign_path: 'https://campaignadmin-dot-prod-marketing-digital.uc.r.appspot.com',
  management_path: 'https://managementadmin-dot-prod-marketing-digital.uc.r.appspot.com',
  management_logger: 'https://managementlogger-dot-prod-marketing-digital.uc.r.appspot.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
